import { useStaticQuery } from "gatsby";
import React from "react";
import Galleries from "../gallery/Galleries";

const ProjectList = ({ images = [] }) => {
  return <Galleries data={images} />;
};

function ProjectStatusContent() {
  const data = useStaticQuery(graphql`
    {
      allOngoingJson(
        filter: { is_active: { eq: true } }
        sort: { order: DESC, fields: id }
      ) {
        edges {
          node {
            title
            sections {
              title
              project_updates {
                title
                images {
                  childImageSharp {
                    fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                videos {
                  type
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = [];

  data.allOngoingJson.edges.forEach(({ node }) => {
    node.sections.forEach(item => {
      if (Array.isArray(item.project_updates)) {
        images.push({
          title: `${node.title}${item.title ? ` - ${item.title}` : ""}`,
          gallery: item.project_updates
        });
      }
    });
  });
  return (
    <section className="residence-section">
      <div className="container">
        <div className="pb-4" id="synopsis">
          <h5 className="residence-section-title" id="synopsis">
            Residential Ongoing Projects
          </h5>
          <ProjectList images={images} />
        </div>
      </div>
    </section>
  );
}

export default ProjectStatusContent;
