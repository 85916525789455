import React from "react";
import Layout from "../common/Layout";
import HeroSection from "../common/HeroSection";
import ProjectStatusContent from "./ProjectStatusContent";

function ProjectStatusTemplate() {
  return (
    <Layout>
      <HeroSection title="Project Status" />
      <ProjectStatusContent />
    </Layout>
  );
}

export default ProjectStatusTemplate;
